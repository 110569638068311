button.btn-blog, button.btn-blog:hover, button.btn-blog:active, 
button.btn-blog:active:focus{
    font-family: 'Fredoka Variable';
    font-size: 0.75rem;
    font-weight: 400;
    color: white;
    background-color: #4FC0E8;
    border-radius: 3rem;
    border-color: #4FC0E8;
    padding: 0.10rem 0.75rem 0.10rem 0.75rem;
    float: right;
}

.recent-stories-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.recent-stories-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
}

.recent-stories-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-stories-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
  font-family: 'Fredoka Variable';
}

.recent-stories-posts .post-box .post-title {
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
  font-family: 'Fredoka Variable';
  color: #3e3e40;
  font-size: 1rem;
  font-weight: bold;
}

.recent-stories-posts .post-box .readmore {
  display: flex;
  align-items: center;
  line-height: 1;
  transition: 0.3s;
  font-size: 0.8rem;
  font-family: 'Fredoka Variable';
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

.recent-stories-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-stories-posts .post-box:hover .post-title {
  color: #57C7DC;
}

.recent-stories-posts .post-box .post-img img {
  transition: transform 0.5s ease; /* Smooth transition effect */
}

.recent-stories-posts .post-box:hover .post-img img {
  transform: scale(1.1); /* Zoom effect */
}