.home-text {
    min-width: 0;
}

.home-text h1 {
    font-family: 'Fredoka';
    font-size: 2.75rem;
    color: #5bc9e4;
    font-weight: 400;
}

.dark-text {
    color: #3e3e40;
}

.home-text p {
    font-family: 'Fredoka Variable';
    font-size: 1rem;
    color: #3e3e40;
    font-variation-settings: "wght" 400;
}

.bold {
    font-variation-settings: "wght" 600;
}

button.btn-learn, button.btn-learn:hover, button.btn-learn:active, 
button.btn-learn:active:focus{
    font-family: 'Fredoka Variable';
    font-weight: bold;
    color: white;
    background-color: #4FC0E8;
    border-radius: 1.5rem;
    border-color: #4FC0E8;
    padding: 0.4rem 1.25rem 0.4rem 1.25rem;
    font-size: 1rem;
}

.home-image{
    width: 40vw;
    height: auto;
    max-height: 50vh;
}

.rounded-top-left{
    border-top-left-radius: 1.5rem;
}

.rounded-top-right{
    border-top-right-radius: 1.5rem;
}

.map-text {
    color:white;
    font-family: 'Fredoka Variable';
}

.map-text h1 {
    font-weight: 600;
    font-size: 2.1rem;
}

.map-text p {
    font-weight: 600;
    font-size: 1.3rem;
}

.map-image img {
    height: auto;
    width: 70vw;
}

.stats-text {
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}

.stats-text h1 {
    font-weight: 600;
    font-size: 2.1rem;
}

.stats-text h2 {
    font-weight: 600;
    font-size: 1.8rem;
}

.stat {
    color: #5bc9e4;
    font-size: 3rem;
}

.company-num {
    font-family: 'Fredoka Variable';
    font-weight: 600;
    font-size: 2.1rem;
    color: white;
}

@keyframes slide {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(-240rem);
    }
}

.scrolling-image-container {
    overflow: hidden;
}