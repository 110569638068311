.about-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}
.about-text h1{
    font-weight: bold;
    font-size: 3.3rem;
}
.about-text p{
    font-size: 1.2rem;
}
.about-text h2{
    font-size: 2.1rem;
    font-weight: 700;
}
.new-logo{
    width: 30rem;
}
.text-box{
    margin-left: 2rem;
    font-family: 'Fredoka Variable';
}

.about-title {
    font-family: 'Fredoka Variable';
    font-weight: 700;
    font-size: 2.1rem;
    color: #3e3e40;
}

/* FOR TIMELINE */
.vertical-timeline-element {
    font-family: 'Fredoka Variable';
}

.vertical-timeline-element h3 {
    font-weight: 600;
}

.vertical-timeline-element P {
    color: #3e3e40;
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  border: 4px solid #3e3e40;
  height: 100%;
}

.vertical-timeline:before {
  top: 25px; /* Adjust the position of the lines based on your design */
}

.vertical-timeline-element-date {
    color: #3e3e40;
    font-size: 20x !important;
}

.card{
    height: 300px;
    width: 300px;
    border-radius: 10px;
    padding: 25px 20px 0px 20px;
    border-color: transparent !important;
}
.card-no-padding{
    height: 310px;
    width: 300px;
    border-radius: 10px;
    border-color: transparent !important;
}
.card-text{
    color: white;
}
.card-text h1{
    font-family: 'Fredoka';
    color: #3e3e40;
    text-shadow: 0.5px 0.5px white;
    font-size: 18px;
}
.card-text p{
    font-family: 'Fredoka Variable';
    font-size: 12px;
}
img{
    height: 175px;
    width: auto;
    object-fit: contain;
}
#img-extend{
    height: 203px;
    width: auto;
    object-fit: contain;
}

  

@media (max-width: 768px) { /* Responsive for smaller screens */
    
    .text-box {
        margin-left: 0;
    }
    .new-logo{
        width: 17rem;
    }
}
  
