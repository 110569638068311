.appLogo{
  width: auto;
  height: 1.5rem;
  margin-left: 1rem;
}
.titleDiv{
  color: #3e3e40;
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 1rem;
}
.navbar{
  font-family: 'Fredoka Variable';
}
.nav-item{
  margin-right: 1.25rem;
  text-decoration: none;
  color: #3e3e40;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
}
.nav-item.active,
.nav-item:hover{
  color: white;
  background-color: #4FC0E8;
  border-radius: 3rem;
}
@media (max-width: 992px) { 
  .nav-item{
    padding-left: 2rem;
  }
}