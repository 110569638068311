.apply-icon{
    width: 35vw;
    height: auto;
    max-height: 40vh;
}
.apply-text{
    font-family: 'Fredoka Variable';
    color: #3e3e40;
}
.apply-text h1{
    font-size: 3.1rem;
    font-weight: bold;
}
.apply-text p{
    font-family: 'Fredoka Variable';
    font-size: 1rem;
}
button.btn-apply, button.btn-apply:hover, button.btn-apply:active,
button.btn-apply:active:focus{
    font-family: 'Fredoka Variable';
    font-weight: 600;
    font-size: 1rem;
    color: white;
    background-color: #4FC0E8;
    border-radius: 2rem;
    border-color: #4FC0E8;
    padding: 1rem 1rem 1rem 1rem;
}
.vertical-center{
    min-height: 80vh; 
    display: flex;
    align-items: center;
}
.apply-info, .apply-info:hover{
    color: inherit;
    text-decoration: none;
}