body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Fredoka',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Fredoka';
  src: url('/src/assets/Fredoka.ttf');
}
@font-face {
	font-family: "Fredoka Variable";
	src: url('/src/assets/Fredoka-VariableFont_wdth,wght.ttf') format('truetype');
}
html {
  font-size: calc(15px + 0.390625vw);
}