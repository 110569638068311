.footerLogo{
    width: auto;
    height: 1.5rem;
    margin-left: 1rem;
}
.footerTitle{
    font-family: 'Fredoka';
    color: #3e3e40;
    font-size: 0.95rem;
    margin-left: 1rem;
}
.footerIcon{
    color:#3E3E40;
    width: 1.5rem;
    height: auto;
    margin-top: 1rem;
    margin-right: 1.2rem;
}